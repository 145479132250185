<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password -->
      <b-card class="mb-0">

        <logo />

        <b-card-title class="mb-1">
          {{ $t('Reset Password') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('Your new password must be different from previously used passwords') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="formRules"
          #default="{ invalid }"
        >
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="resetPassword"
          >

            <!-- password -->
            <b-form-group
              :label="$t('New Password')"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Password')"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              :label="$t('Confirm Password')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Confirm Password')"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="invalid || loading"
            >
              <b-spinner
                v-if="loading"
                small
              />
              <span v-if="!loading">{{ $t('Set New Password') }}</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import api from '@/apis/api'
import Logo from '@/views/modules/core/partials/Logo.vue'
import { getHomeRouteForLoggedInUser, setUserData } from '@/auth/utils'

import { coreMethods } from '@/mixins'

export default {
  components: {
    Logo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [coreMethods],
  props: {
    token: {
      type: String,
      default: () => '',
    },
    username: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
      password: '',
      cPassword: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetPassword() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.loading = true
          api.resetPassword({
            username: this.username,
            password: this.password,
            token: this.token,
          })
            .then(response => {
              const { userData, token } = response
              api.setToken(token)
              setUserData(userData)
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser())
                .then(() => {
                  this.showSuccessNotification(this.$t('auth.welcome', { name: userData.fullName || userData.username }) + this.$t('You have successfully logged in to', { company: this.$store.state.app.title }))
                })
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
